<div *ngIf="!isLoading">
    <div fxLayout="column">
        <vi-customer-search></vi-customer-search>
        <section class="section" fxFlex>
            <div fxLayout="column">
                <div *ngIf="showSpinner">
                    <progress-spinner></progress-spinner>
                </div>
                <div *ngIf="systemsFound">
                    <vi-no-systems-found></vi-no-systems-found>
                </div>
                <div *ngIf="systemsLoaded | async">
                    <div class="custom-elevation" style="background-color: white;" *ngIf="!systemsFound"
                         fxLayout="column">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between end">
                            <div fxLayout="row wrap">
                                <div class="search-box">
                                    <mat-form-field class="search-form-field" appearance="outline">
                                        <mat-label
                                            class="label-appearance">{{ 'listPage.searchLabel' | translate }}</mat-label>
                                        <mat-icon matPrefix class="prefix">search</mat-icon>
                                        <input class="search-input" matInput (keyup)="applyFilter($event.target.value)">
                                    </mat-form-field>
                                </div>
                                <div class="or-field" *ngIf="!this.userInformationService.isEK()">
                                    {{ 'listPage.or' | translate }}
                                </div>
                                <div class="search-box" *ngIf="!this.userInformationService.isEK()">
                                    <mat-form-field class="search-form-field" appearance="outline">
                                        <mat-label
                                            class="label-appearance">{{ 'listPage.serialSearchLabel' | translate }}</mat-label>
                                        <mat-icon matPrefix class="prefix">search</mat-icon>
                                        <input class="search-input" matInput name="serialNumberSearch"
                                               (keydown.enter)="getHeadEquipment($event)">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div style="place-content: end" fxLayout="row wrap">
                                <div class="register"
                                     *ngIf="this.userInformationService.registrationLink()">
                                    <div *ngIf="systemsLoaded | async">
                                        <a mat-raised-button
                                           href="{{heatingSystemRegistrationBaseUrl}}"
                                           color="primary">{{ 'buttons.registration' | translate }}</a>
                                    </div>
                                </div>
                                <div class="vitoguide" *ngIf="!this.userInformationService.isEK()">
                                    <a mat-raised-button
                                       href="{{viGuideBaseUrl}}"
                                       target="_blank" rel="noopener noreferrer"
                                       color="primary">
                                        {{ 'buttons.toVitoguide' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <table mat-table [dataSource]="dataSourceSystem">

                            <ng-container matColumnDef="equipmentNumber">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.id' | translate }}</th>
                                <td mat-cell *matCellDef="let element" class='m-card-title' data-label='Anlagen-ID'>
                                    {{element.equipmentNumber}} </td>
                            </ng-container>

                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.name' | translate }}</th>
                                <td mat-cell *matCellDef="let element" class='m-card-sub-title'
                                    data-label='Bezeichnung'>
                                    <div
                                        *ngIf="(element.isRegistrated && element.isRegistrated !== '2' && element.isRegistrated !== '4') ||
                                        this.userInformationService.isVi() ||
                                        this.userInformationService.isEK()">
                                        {{element.name}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="country">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.country' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.country}} </td>
                            </ng-container>

                            <ng-container matColumnDef="street">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.street' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div
                                        *ngIf="(element.isRegistrated && element.isRegistrated !== '2' && element.isRegistrated !== '4') ||
                                        this.userInformationService.isVi() ||
                                        this.userInformationService.isEK()">
                                        {{element.street}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="houseNumber">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.numberAbbrev' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    <div
                                        *ngIf="(element.isRegistrated && element.isRegistrated !== '2' && element.isRegistrated !== '4') ||
                                        this.userInformationService.isVi() ||
                                        this.userInformationService.isEK()">
                                        {{element.houseNumber}}
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="postalCode">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.postalCode' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.postalCode}} </td>
                            </ng-container>

                            <ng-container matColumnDef="city">
                                <th mat-header-cell *matHeaderCellDef> {{ 'listPage.location' | translate }}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.city}} </td>
                            </ng-container>

                            <ng-container matColumnDef="isRegistrated">
                                <th mat-header-cell *matHeaderCellDef>
                                    <div *ngIf="!this.userInformationService.isEK()">
                                        {{ 'listPage.registrationStatus' | translate }}
                                    </div>
                                </th>
                                <td mat-cell *matCellDef="let element" fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <div
                                        *ngIf="!this.userInformationService.isEK()">
                                        <mat-icon *ngIf="element.isRegistrated === '1'" aria-hidden="false"
                                                  aria-label="check"
                                                  style="color: #31D47C; width: 24px; height: 24px; vertical-align: middle; padding-right: 24px;">
                                            check_circle_outline
                                        </mat-icon>
                                        <mat-icon *ngIf="element.isRegistrated === '3'" aria-hidden="false"
                                                  aria-label="error"
                                                  style="color: #F9BD5F; width: 24px; height: 24px; vertical-align: middle; padding-right: 24px;"
                                                  matTooltip=" {{ 'hoverInfo.notFullyRegistered' | translate }}">
                                            error_outline
                                        </mat-icon>
                                        <mat-icon *ngIf="element.isRegistrated === '5'" aria-hidden="false"
                                                  aria-label="error"
                                                  style="color: #F9BD5F; width: 24px; height: 24px; vertical-align: middle; padding-right: 24px;"
                                                  matTooltip=" {{ 'hoverInfo.preregistered' | translate }}">
                                            warning_amber
                                        </mat-icon>
                                    </div>
                                    <div
                                        *ngIf="this.userInformationService.registrationLink()">
                                        <a mat-raised-button class="register-mat-icon"
                                           href="{{heatingSystemRegistrationEquiUrl.replace('{systemId}', element.equipmentNumber)}}"
                                           color="primary">
                                            <mat-icon aria-hidden="false" aria-label="edit"
                                                      style="width: 24px; height: 24px; vertical-align: middle;">
                                                mode_edit
                                            </mat-icon>
                                        </a>
                                    </div>
                                    <div
                                        *ngIf="this.userInformationService.isVi()">
                                        <a class="register-mat-icon" color="primary"
                                           href="{{this.getFioriUrl(this.fioriEquipmentUrl, element.equipmentNumber)}}"
                                           mat-raised-button>
                                            <mat-icon aria-hidden="false" aria-label="edit"
                                                      style="width: 24px; height: 24px; vertical-align: middle;">
                                                mode_edit
                                            </mat-icon>
                                        </a>
                                    </div>
                                </td>

                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row (click)="openDetails(row)"
                                *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <mat-progress-bar *ngIf="this.systemsWithDelay" mode="indeterminate"></mat-progress-bar>
                        <mat-paginator *ngIf="this.dataSourceSystem.data.length > 25"
                                       [pageSizeOptions]="[25, 50, this.entries]"
                                       showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div *ngIf="isLoading">
    <progress-spinner></progress-spinner>
</div>
