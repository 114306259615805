import {Component, OnDestroy, OnInit, Injectable} from '@angular/core';
import {AuthService} from './services/auth.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {TranslationConfigService} from './services/translation-config.service';
import {ActivatedRoute} from '@angular/router';
import {Country} from './models/country.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Title} from '@angular/platform-browser';
import {forkJoin, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Component({
    selector: 'vi-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {


    constructor(public activatedRoute: ActivatedRoute, private authService: AuthService, private translate: TranslateService,
                private translationConfig: TranslationConfigService, private titleService: Title) {
    }

    ngOnInit(): void {
        this.authService.init();
        if (window.navigator.language) {
            this.translationConfig.setUserLanguage(window.navigator.language);
            this.translate.setDefaultLang(this.translationConfig.getDefaultLanguage());
            this.translate.use(this.translationConfig.getUserLanguage());
        }

        this.activatedRoute.queryParams.subscribe(params => {
            if (params && params.lang) {
                this.translationConfig.setUserLanguage(params.lang);
                this.translate.setDefaultLang(this.translationConfig.getDefaultLanguage());
                this.translate.use(this.translationConfig.getUserLanguage());
            } else {
                this.translate.setDefaultLang(this.translationConfig.getDefaultLanguage());
                this.translate.use(this.translationConfig.getDefaultLanguage());
            }
        });

        // Set title
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translate.get('application.title').subscribe((res: string) => {
                this.titleService.setTitle(res);
            });
        });
    }

    ngOnDestroy(): void {
    }


}

@Injectable()
export class ConfigService {

    public message: string;
    public country: string;
    private API_URL_REG = environment.heatingSystemRegistrationApi;
    private API_URL_OVER = environment.apiUrl;

    constructor(private translationConfig: TranslationConfigService, private http: HttpClient) {}

    load(): Promise<boolean> {

        let userCity;
        if (Intl) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const tzArr = userTimeZone.split('/');
            userCity = tzArr[tzArr.length - 1];
        }
        const body = {name: userCity};
        const sources = [
            this.http.get<Country[]>(this.API_URL_REG + 'settings/available-countries'),
            this.http.post<Country>(this.API_URL_OVER + '/user/country-by-timezonecity', body)
        ];

        return new Promise<boolean>((resolve) => {
            return forkJoin(sources).subscribe(([countries, country]) => {
                // All data available
                this.translationConfig.setAvailableCountries((countries as Country[]));
                this.country = (country as Country).country;
                resolve(true);
            });
        });

    }

}

export function configServiceFactory(config: ConfigService) {
    return () => config.load();
}

