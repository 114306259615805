import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../models/user.model';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TranslationConfigService} from './translation-config.service';

declare global {
    interface Window {
        dataLayer: any;
    }
}

const API_URL_USER = environment.usersMe;

@Injectable({
    providedIn: 'root'
})
export class UserInformationService {


    user: User;
    loggedIn: boolean;
    shownCustomer: string;
    shownEquipment: string;
    customerInit: boolean;
    userType = 'b2c';
    country: string;
    language: string;

    private appReadySource = new Subject<string>();
    appReadyChanged$ = this.appReadySource.asObservable();
    private userPortalUrl = environment.userPortalUrl;
    private userPortalUrlDe = environment.userPortalUrlDe;
    private userPortalUrlFr = environment.userPortalUrlFr;
    private userPortalUrlIt = environment.userPortalUrlIt;
    serialSearch: string;
    equiSearch:string;

    constructor(private http: HttpClient, private router: Router, private translate: TranslateService, private translationConfig: TranslationConfigService) {
    }

    initCurrentUser() {
        if (!this.loggedIn) {
            this.http.get<User>(API_URL_USER).subscribe(user => {
                const isLiteAccount = this.detectIsLiteAccount(user);
                const isCountrySupported = this.detectCountryIsSupported(user);
                const identityType = this.getIdentityType(user);
                this.detectUserPortalRedirect(user);
                this.user = user;
                this.formatLocale();
                this.setLanguage();
                this.translationConfig.setUserLanguage(this.user.locale);
                this.translate.use(this.translationConfig.getUserLanguage());
                if (isLiteAccount) {
                    this.router.navigate(['/lite-account']);
                    this.appIsLoaded();
                } else if (!isCountrySupported) {
                    this.router.navigate(['/country-not-supported']);
                } else {
                    this.setUser();
                    console.log('Login');
                    // push identity type and iam id to dataLayer for Google Analytics
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'user_profile_loaded',
                        'identityType': identityType.value,
                        'userId': user.id,
                        'userProfileLoaded': true
                    });
                    this.appIsLoaded();
                }
            });
        } else {
            this.appIsLoaded();
        }
    }

    getUser(): User {
        return this.user;
    }

    setUser() {
        this.loggedIn = true;
        this.setRole();
        this.userType = this.isEK() ? 'b2c' : 'b2b';
        this.setCustomerId();
        if (!this.customerInit) {
            this.initShownCustomer();
            this.customerInit = true;
            this.shownEquipment = null;
        }
    }

    formatLocale() {
        if (this.user.locale && this.user.locale.indexOf('-') > 0) {
            this.user.locale = this.user.locale.split('-')[0].toLowerCase() + '-' + this.user.locale.split('-')[1].toUpperCase();
        } else if (this.user.locale) {
            this.user.locale = this.user.locale.toLowerCase();
        }
    }

    setLanguage() {
        // set user country
        let language;
        if (this.user.locale !== undefined && this.user.locale.indexOf('-') > 1) {
            language = this.user.locale.split('-')[0].toLowerCase();
        } else if (this.user.languageCode !== undefined) {
            language = this.user.languageCode.toLowerCase();
        }
        this.language = language;
    }

    setRole() {
        for (let i = 0; i < this.user.properties.length; i++) {
            if (this.user.properties[i].name == 'IdentityType') {
                this.user.role = this.user.properties[i].value;
                break;
            }
        }
        //set serviceConfigurator Role
        this.user.serviceConfiguratorLink = this.detectServiceConfiguratorRole(this.user);
    }

    setCustomerId() {
        for (let i = 0; i < this.user.properties.length; i++) {
            if (!this.isVi() && this.user.company && this.user.company.id) {
                this.user.customerId = parseInt(this.user.company.id, 10).toString();
                break;
            } else {
                this.user.customerId = 'id-not-found';
            }
        }
    }

    setShownCustomer(customerId) {
        this.shownCustomer = customerId;
    }

    private detectIsLiteAccount(user: User) {
        return user.isLite;
    }

    private detectServiceConfiguratorRole(user: User) {
        if (user.applicationRoles) {
            return user.applicationRoles.includes('Serviceconfigurator.Default');
        }
    }

    public detectEmployee(user: User) {
        return this.getIdentityType(user).value === 'ViInt' || this.getIdentityType(user).value === 'ViExt' || this.getIdentityType(user).value === 'ViAcc';
    }

    public detectEK(user: User) {
        return this.getIdentityType(user).value === 'EK';
    }

    public isEK() {
        return this.user.role === 'EK';
    }

    public isKuMA() {
        return this.user.role === 'KuMA';
    }

    public isVi() {
        return this.user.role === 'ViInt' || this.user.role === 'ViExt' || this.user.role === 'ViAcc';
    }

    public detectConsumer(user: User) {
        return this.getIdentityType(user).value === 'KuMA';
    }

    private getIdentityType(user: User) {
        return user.properties.find(property => property.name === 'IdentityType');
    }

    async appIsLoaded() {
        this.appReadySource.next('ready.');
    }

    private detectCountryIsSupported(user: User) {

        // set user country
        let country;
        if (user.countryCode !== undefined) {
            country = user.countryCode.toUpperCase();
        } else if (user.locale !== undefined && user.locale.indexOf('-') > 1) {
            country = user.locale.split('-')[1].toUpperCase();
        } else if (user.address !== undefined && user.address.countryCode !== undefined) {
            country = user.address.countryCode.toUpperCase();
        } else if (user.company !== undefined && user.company.countryCode !== undefined) {
            country = user.company.countryCode.toUpperCase();
        }
        this.country = country;
        if (this.detectEmployee(user)) {
            return true;
        } else if (this.detectEK(user) && (country === 'FR' || country === 'IT' || country === 'ES' || country === 'US' || country === 'CA')) {
            return false;
        }
        return this.translationConfig.getSupportedCountries().indexOf(country) > -1;
    }

    private initShownCustomer() {
        if (this.isEK()) {
            this.shownCustomer = 'EK';
        } else if (this.user.customerId !== 'id-not-found') {
            this.shownCustomer = this.user.customerId;
        } else {
            this.shownCustomer = '';
        }
    }

    public partnerPortalExists(): boolean {
        return this.country === 'DE' || this.country === 'BE' || this.country === 'IT';
    }

    checkCeoOrAdmin() {
        return this.user.businessRoles !== undefined &&
            (this.user.businessRoles.includes('Geschäftsführer') || this.user.businessRoles.includes('Administrator'));
    }

    public registrationLink(): boolean {
        if (this.isVi()) {
            return false;
        } else if (this.isEK()) {
            if (this.country === 'SK' || this.country === 'CZ' || this.country === 'RO') {
                return false;
            }
        }
        return true;
    }

    private detectUserPortalRedirect(user: User) {
        const redirectCountries = ['DE', 'CH', 'AT'];

        if (this.shouldRedirectUser(user, redirectCountries)) {

            const { languageCode, countryCode } = this.getUserLanguageAndCountry(user);

            if (languageCode === 'de') {
                this.redirectToPortal(this.userPortalUrlDe, countryCode, languageCode);
            } else if (languageCode === 'fr') {
                this.redirectToPortal(this.userPortalUrlFr, countryCode, languageCode);
            } else if (languageCode === 'it') {
                this.redirectToPortal(this.userPortalUrlIt, countryCode, languageCode);
            } else {
                window.location.href = this.userPortalUrl;
            }
        }
    }

    private shouldRedirectUser(user: User, redirectCountries: string[]): boolean {
        return this.detectEK(user) && (
            redirectCountries.includes(user.countryCode?.toUpperCase() || '') ||
            redirectCountries.includes(user.address?.countryCode?.toUpperCase() || '') ||
            redirectCountries.includes(user.customerData?.country?.toUpperCase() || '')
        );
    }

    private redirectToPortal(urlTemplate: string, countryCode: string, languageCode: string) {
        const url = urlTemplate.replace('{countryCode}', countryCode).replace('{languageCode}', languageCode);
        window.location.href = url;
    }
    private getUserLanguageAndCountry(user: User): { languageCode: string, countryCode: string } {
        const languageCode = user.languageCode?.toLowerCase() || user.locale?.split('-')[0].toLowerCase();
        const countryCode = user.countryCode ||
            user.address?.countryCode ||
            user.customerData?.country ||
            user.locale?.split('-')[1].toUpperCase();

        return { languageCode, countryCode };
    }
}
